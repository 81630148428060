<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="wrapper">

                <div class="pagetitle">
                    <h1>Car <span class="green">Buyers Guide</span></h1>
                </div>

            </div>
        </div>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="wrapper">

                <div class="tips">
                    <div class="tips__masthead">
                        <picture>
                            <source
                                media="(max-width: 1023px)"
                                srcset="../assets/img/photos/car-buyers-guide/car-buyers-guide-new.png">
                            <source
                                media="(min-width: 1024px)"
                                srcset="../assets/img/photos/car-buyers-guide/car-buyers-guide-new.png">
                            <img src="../assets/img/photos/car-buyers-guide/car-buyers-guide-new.png"
                                 alt="car buyers guide masthead">
                        </picture>
                    </div>


                    <div class="tips__content">
                        <h2>You’re looking to buy a used car, but where do you start?</h2>
                        <p class="subheading">We’ve put together this handy guide to take away some of the mystery from
                            the process and to give you some tips to make the journey go smoothly. These three steps
                            will take you from thinking about buying a used car to owning one.</p>
                        <div class="tips__image tips__image--right">
                            <picture>
                                <img src="../assets/img/photos/car-buyers-guide/research.png"
                                     alt="car buyers guide">
                            </picture>
                        </div>
                        <h3><span>Step 1:</span> Research</h3>
                        <p>
                            There are some questions you need to ask yourself before buying a car.
                        </p>
                        <ol>
                            <li>What do you need it for?</li>
                            <li>How big a car do you need?</li>
                            <li>What is your budget?</li>
                        </ol>
                        <p>
                            You’ll find the resources on this site a great help in your research. Our reviews will give
                            you a sense of which car may be right for you and you can compare cars from all over the
                            country here. Check what the sellers have said about them, view the photos they’ve taken,
                            and compare the features and prices to help you make up your mind.
                        </p>

                    </div>
                    <div class="spacer"></div>
                    <div class="tips__content">
                        <div class="tips__image tips__image--right">
                            <picture>
                                <img src="../assets/img/photos/car-buyers-guide/inspectingcar.png"
                                     alt="car buyers guide">
                            </picture>
                        </div>
                        <h3 class=""><span>Step 2:</span> Inspecting The Car</h3>
                        <p>You’ve done the hard work, reading reviews, doing the sums, finally deciding on a car that’s
                            right for you.</p>
                        <p>
                            <strong>What’s next?</strong><br>
                            Contact the seller with any questions you have about the car and arrange a meeting for you to view it in person.
                            If possible, you should ask a mechanic along with you when going to view a car, but if you can’t arrange one (or even if you can), here’s what you should keep in mind when you’re going to view the car.</p>

                        <div class="icon-topics">
                            <div>

                            </div>

                        </div>


                        <div class="mb-5"></div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/view.svg"
                                             width="44"
                                             height="44"
                                             alt="view icon">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>View the car during the day, not at night. </h4>
                                <p>This one may seem obvious, but you want to be able to see the car in full daylight for a proper inspection. We all lead busy lives, work during the day, commitments on the weekends. So, it’s understandable that many used car buyers are tempted to break this rule and view potential purchases in the evenings. But it really is important to get a proper look at the condition of the car and artificial light will never beat daylight for this.
                                    In an ideal world you’d also choose a bright dry day rather than a wet overcast one (rain can make it harder to inspect the exterior).</p>
                            </div>
                        </div>


                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/home.svg"
                                             width="41"
                                             height="41"
                                             alt="home icon">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>If buying from a private seller: Don’t meet them somewhere more convenient.</h4>
                                <p>Meet them at their home address. If the seller isn’t willing to share their address, there’s probably something fishy going on. Any seller should be happy to give you their address. This gives you the ability to follow up with them after making a deal in case something goes wrong with the car. If you’ve arranged to meet in a supermarket car park or the like you’ll have fewer options for recourse.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/doc.svg"
                                             width="36"
                                             height="45"
                                             alt="documents">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Check the documentation.</h4>
                                <p>Make sure the numbers on the documentation match the numbers on the car itself. You should be checking that the chassis number (found either on the door post or where the windscreen meets the dashboard on the driver’s side) matches the paperwork. Check any service records that you can, see if the mileage makes sense compared to the last reports. Find out when the car is due another service and what that may entail. How did the vehicle fare in its last NCT? Is there anything you should be looking out for at the next one?</p>
                            </div>
                        </div>


                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/tires.svg"
                                             width="41"
                                             height="41"
                                             alt="tyres">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Check the tyres.</h4>
                                <p>Bear in mind that kicking the tyres won’t tell you anything. You should run your hand over the tread to get a feel for evenness; is the tread worn more on one side of the tyre than the other? This could be a sign of imbalance. How much tread is left on the tyres? The legal requirement in the EU is a tread depth of 1.6mm – any less than this and the tyres need to be replaced – but some authorities recommend replacing tyres once they are worn past 3mm. You can use a €1 coin to check the tread depth. The gold rim is 3mm thick so if any of it is showing when you insert the coin into the tread you know you’ll need to replace the tyres soon.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/doors.svg"
                                             width="43"
                                             height="41"
                                             alt="Doors">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Check the bodywork.</h4>
                                <p>A good once-over of all the panels can tell you a lot about a car’s history. You can visually gauge how hard a life the car has had by its paintwork. Are there pockmarks or scratches? Running your hands over the bodywork will also give you a sense of any dents and bruises. Check the gaps between doors and panels. If bits aren’t aligning properly it could be a sign of past or current damage. Check for rust. Finding rust on one part of the car could be an indication that there are further issues.</p>

                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/fluids.svg"
                                             width="58"
                                             height="29"
                                             alt="Fluids">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Check the fluid levels.</h4>
                                <p>Under the bonnet there are a few fluid levels to take a peek at. The brake fluid reservoir should be full – if it’s not it could be a sign of neglect, or worse, an issue with the brake pistons. When checking the oil there’s a goldilocks zone. You don’t want to see the oil level too low of course, running an engine with low oil could have caused serious damage over time. But also, seeing the oil level too high could be an indication that someone has been frequently topping it up, possibly due to a leak etc. </p>
                                <p> The colour of the oil on the dipstick is important if you’re looking at a petrol car (a diesel engine will darken engine oil pretty quickly), if the oil is black in a petrol engine it means it’s pretty old and should have been changed more recently. The amount of liquid left in the wind-screen washer isn’t vitally important, but if it’s empty it could be a sign that the last owner wasn’t overly conscientious.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/seat.svg"
                                             width="35"
                                             height="40"
                                             alt="Seat">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Take a good look inside the car.</h4>
                                <p>Make sure to check for missing internal panels on the inside of the doors, check out the parcel shelf etc. Most likely, these won’t be deal breakers, but you’ll want to be aware of anything you may have to repair or replace.
                                    When inside the car, be aware of the smell as well, you’ll want to make sure there aren’t any issues with damp.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/odometer.svg"
                                             width="45"
                                             height="40"
                                             alt="Odometer">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Fire up the ignition and pay attention to the dashboard.</h4>
                                <p>It’s best to give the car a cold start, you’ll want to hear what the engine sounds like when it’s working its hardest. You should notice anything amiss with the sound of the ignition. Do pay attention to the dashboard lights as well. Are there any warnings you should be aware of?
                                    Try out all the controls. The radio, air-conditioning, sat-nav, camera etc. Be happy that everything is in working order.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/beam.svg"
                                             width="41"
                                             height="29"
                                             alt="Beam">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>Check that all the lights are in working order.</h4>
                                <p>Switch on the lights and take a walk around the car, make sure they’re all working. Ask the seller to operate the brakes for you when you’re behind the vehicle so you can see them in operation.</p>
                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/wheel.svg"
                                             width="39"
                                             height="39"
                                             alt="Wheel">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h4>This should go without saying, but take it for a test-drive.</h4>
                                <p>When on your test-drive there are a few things to look out for. The seller may not be happy to let you drive off by yourself, if they do, then great, you can concentrate on getting to know your potential new car and finding out about any potential faults. If they would rather come with you, ask them if they could be quiet and let you hear the car, you can talk all about the vehicle when you’re parked back at their house or the dealership.</p>

                                <p>Whether you take the car for a test-drive by yourself or with the seller, keep the radio off. This will give you the chance to listen out for any noises that the car shouldn’t be making which could be indications of faults.
                                    Try and plan a route that will take in a few different kinds of road, you’ll want to see how the car handles in different conditions. If you can find a poor-quality road or one with speed bumps, listen out for any rattles or clunks and see how the suspension feels.</p>

                                <p>Ideally you should plan to give yourself between 20 minutes and half an hour of driving to get a good sense of the vehicle and in this time you should try to find an empty stretch of road to perform some braking tests as well as testing how straight the car drives. With your hands lightly on the steering wheel, see if the car wants to drift off in either direction.</p>

                            </div>
                        </div>

                        <div class="myrow">
                            <div class="left">
                                <div class="tips__image tips__image--left icon">
                                    <picture>
                                        <img src="../assets/img/photos/car-buyers-guide/icons/car.svg"
                                             width="46"
                                             height="32"
                                             alt="Car">
                                    </picture>
                                </div>
                            </div>
                            <div class="right">
                                <h3>Do you like the car?</h3>
                                <p>Apart from all the potential fault finding, the most important part of viewing and test-driving the car is to find out whether you actually want it.</p>
                                <ol class="likedcar">
                                    <li>How does it drive?</li>
                                    <li>How comfortable is it?</li>
                                    <li>Is there enough space inside for your needs?</li>
                                    <li>Do you want to own and drive this car?</li>
                                </ol>
                                <p>Remember, a car is quite an important purchase, you could potentially be driving it for quite a few years, so you should be happy with it before you buy it.</p>
                            </div>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="tips__content">
                        <h3><span>Step 3:</span> Making the Deal</h3>
                        <div class="tips__image tips__image--right--deal">
                            <picture>
                                <img src="../assets/img/photos/car-buyers-guide/car-buyers-guide-deal.jpg"
                                     width="388"
                                     alt="Make the deal">
                            </picture>
                        </div>
                        <p>You love the car, now to go about making it your own. Dealerships take a lot of the pain out
                            of buying used cars and have financing options available on many of their models. But if
                            you’re dealing with a private seller there are a few things to bear in mind.</p>
                        <p>Before signing over ownership of the car you should have your own history check carried out,
                            even if the seller has already had one done. There are many websites you can use for this
                            and they are inexpensive, you’ll get a report telling you if that car was ever written off
                            or is still under finance.</p>
                        <p>Once you’re happy to go ahead, you’ll have to agree on the price. You both will have done
                            your research and should both be aware of the current market value of the vehicle. If the
                            price is a lot lower than the rest of the marketplace you should be asking yourself some
                            questions. If it’s a lot higher, then the seller may have an inflated value of the vehicle
                            in their mind.</p>
                        <p>You’ve driven the car and know its history and any potential problems that may arise soon.
                            Decide on the price you think is fair for the car taking into account what you know about
                            it. Anything missing or needing replacing can be used as leverage to get the price down a
                            bit but, at the end of the day the seller will also have a price in their head that they
                            think is fair. This is a business arrangement and the best outcome is one where both parties
                            are happy.</p>
                        <p>When you’ve reached an agreement bank transfer is the safest option for paying the seller.
                            You don’t really want to be walking around with handfuls of cash and the seller won’t have
                            to either. Once all the paperwork has been formalised and you’ve got both keys, all that’s
                            left to do is enjoy your new used car.</p>
                    </div>
                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter'
import Breadcrumbs from '@/components/partials/Breadcrumbs'

export default ({
    name: "CarBuyersGuide",
    components: {
        MainHeader,
        MainFooter,
        Breadcrumbs
    },
    data: function () {
        return {
            title: "Car Buyers Guide, Car Buying Tips, Car Buying Process, Ireland | Terrific.ie",
            description: "A car buyers guide and buying tips for purchasing a used car in Ireland. Our guide for car buying is provided on a goodwill basis.",
            keywords: "Car Buyers Guide, Car Buying Guide, Car Buyers, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
            robots: "all"
        }
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    serverPrefetch() {
        this.$ssrContext.title = this.title;
        this.$ssrContext.description = this.description;
        this.$ssrContext.keywords = this.keywords;
        this.$ssrContext.robots = this.robots;
    }
})
</script>

<style scoped>
</style>
